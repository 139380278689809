<ng-container *ngIf="show" style="align-self: center" class="noprint">
    <div class="card m-3 noprint">
        <h5 class="card-header">Add Product</h5>
        <div class="card-body">
            <form [formGroup]="addproduct" (ngSubmit)="onSubmit()" class="noprint">
                <div class="form-row">
                    <div class="form-group col-3">
                        <label>Products Code</label>
                        <input type="text" formControlName="Code" class="form-control" value=Product.Prod.name
                            [ngClass]="{ 'is-invalid': submitted && f.Code.errors }" />
                        <div *ngIf="submitted && f.Code.errors" class="invalid-feedback">
                            <div *ngIf="f.Code.errors.required"></div>
                        </div>
                    </div>
                    <div class="form-group col">
                        <label>Product Name</label>
                        <select formControlName="Product" id="Product" class="form-control" #mySelect
                            (change)='onOptionsSelected()' [ngClass]="{ 'is-invalid': submitted && f.Product.errors }">
                            <option *ngFor="let Prod of products" [ngValue]="Prod.name" orderBy="Prod.name">
                                {{Prod.name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.Product.errors" class="invalid-feedback">
                            <div *ngIf="f.Product.errors.required">Product name is required</div>
                        </div>
                    </div>
                    <div class="form-group col-3">
                        <label>Lot Number</label>
                        <input type="text" formControlName="LotNo" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.LotNo.errors }" />
                        <div *ngIf="submitted && f.LotNo.errors" class="invalid-feedback">
                            <div *ngIf="f.LotNo.errors.required">Lot number is required</div>
                        </div>
                    </div>
                    <div class="form-group col-3">
                        <label>Quantity</label>
                        <input type="number" formControlName="Quantity" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.Quantity.errors }" />
                        <div *ngIf="submitted && f.Quantity.errors" class="invalid-feedback">
                            <div *ngIf="f.Quantity.errors.required">Quantity is required</div>
                        </div>
                    </div>
                    <div class="form-group col-3">
                        <label>Cost price(C$/KG)</label>
                        <input type="number" formControlName="Price" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.Price.errors }" />
                        <div *ngIf="submitted && f.Price.errors" class="invalid-feedback">
                            <div *ngIf="f.Price.errors.required">Price is required</div>
                        </div>
                    </div>
                    <div class="form-group col-3">
                        <label>Damaged</label>
                        <input type="number" formControlName="Damage" class="form-control">
                    </div>
                    <div class="form-group col-3">
                        <label>Reserved</label>
                        <input type="number" formControlName="Reserved" class="form-control">
                    </div>
                    <div *ngIf="hide" class="form-group col-3" [hidden]="addproduct.controls.Availiable == 'true'">
                        <label>Availiable</label>
                        <input type="number" formControlName="Availiable" class="form-control">
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-row">
                        <div class="form-group col">
                            <label>Location</label>
                            <select formControlName="Inventory" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.Inventory.errors }">
                                <option *ngFor="let inv of inventory" [ngValue]="inv.name">
                                    {{inv.name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.Inventory.errors" class="invalid-feedback">
                                <div *ngIf="f.Inventory.errors.required">Inventory name is required</div>
                            </div>
                        </div>
                        <div class="form-group col">
                            <label>Quality</label>
                            <select formControlName="Quality" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.Quality.errors }">
                                <option *ngFor="let qltty of quality" [ngValue]="qltty.name">
                                    {{qltty.name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.Quality.errors" class="invalid-feedback">
                                <div *ngIf="f.Quality.errors.required">Quality is required</div>
                            </div>
                        </div>
                        <div class="form-group col">
                            <label>Creation Date</label>
                            <input type="date" formControlName="CreatedDate" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.CreatedDate.errors }" />
                            <div *ngIf="submitted && f.CreatedDate.errors" class="invalid-feedback">
                                <div *ngIf="f.CreatedDate.errors.required">Creation Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-group col">
                        <label>Notes</label>
                        <textarea type="text" formControlName="Notes" class="form-control">
                </textarea>
                    </div>
                </div>

                <div class="form-group form-check">
                    <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input"
                        [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
                    <label for="acceptTerms" class="form-check-label">Are you sure you want to add this record?</label>
                    <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Please select if you want to
                        add a record</div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary mr-1">Save</button>
                    <button class="btn btn-secondary" type="reset" (click)="onReset(); toggle(false)">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>
<span class="noprint">

</span>
<ng-container *ngIf="printShow" style="align-self: center" class="noprint">
    <div class="card m-3 noprint">
        <h5 class="card-header noprint" style="text-align: center">Select items to print</h5>
        <div class="card-body noprint">
            <form [formGroup]="printselection" (ngSubmit)="onSubmit()" class="noprint">
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Code" id="Code" class="form-check-input" />
                    <label for="Code" class="form-check-label">Code</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Product" id="Product" class="form-check-input" />
                    <label for="Code" class="form-check-label">Product</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="LotNo" id="LotNo" nname="Lot No."
                        class="form-check-input" />
                    <label for="Code" class="form-check-label">Lot No</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Quantity" id="Quantity" class="form-check-input" />
                    <label for="Code" class="form-check-label">Quantity</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Price" id="Price" class="form-check-input" />
                    <label for="Code" class="form-check-label">Price</label>
                </div>

                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Reserved" id="Reserved" class="form-check-input" />
                    <label for="Code" class="form-check-label">Reserved</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Availiable" id="Availiable" class="form-check-input" />
                    <label for="Code" class="form-check-label">Availiable</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Damage" id="Damage" class="form-check-input" />
                    <label for="Code" class="form-check-label">Damage</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Inventory" id="Inventory" class="form-check-input" />
                    <label for="Code" class="form-check-label">Inventory</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Quality" id="Quality" class="form-check-input" />
                    <label for="Code" class="form-check-label">Quality</label>
                </div>
                <div class="form-group form-check col-3">
                    <input type="checkbox" formControlName="CreatedDate" id="CreatedDate" class="form-check-input" />
                    <label for="Code" class="form-check-label">Created Date</label>
                </div>
                <div class="form-group form-check col-2">
                    <input type="checkbox" formControlName="Notes" id="Notes" class="form-check-input" />
                    <label for="Code" class="form-check-label">Notes</label>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary mr-1 btn-sm" (click)="export()">Print</button>
                    <button class="btn btn-secondary btn-sm" type="reset" (click)="onReset()">Reset
                        selection</button>&nbsp;
                    <button class="btn btn-secondary btn-sm" type="reset" (click)="closeprint()">Close</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>
<br>
<div *ngIf="mainShow" class="noprint">

    <button class="btn btn-secondary mr-1 btn-sm noprint" (click)="toggle(true,'add')"><span
            class="glyphicon glyphicon-plus"></span>Add
    </button>
    <button class="btn btn-secondary mr-1 btn-sm noprint" (click)="getSelectedRows()">Edit
    </button>
    <span></span>
    <button class="btn btn-secondary mr-1 btn-sm noprint" (click)="delete(true)">Delete
    </button>
    <button class="btn btn-secondary mr-1 btn-sm noprint" (click)="print()"> Print report
    </button>
    <br>
    <div class="noprint">
        <ag-grid-angular #agGrid id="myGrid" style="width: 100%; height: 450px;" class="ag-theme-alpine"
            [defaultColDef]="defaultColDef" [rowData]="rowDatasource | async" [columnDefs]="columnDefsorder"
            rowSelection="single" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>

<table class="printonly">
    <thead>
        <div class="header-space">&nbsp;</div>
    </thead>
    <tbody>
        <td>
            <table mat-table [dataSource]="gridData">
                <ng-container [matColumnDef]="column" *ngFor="let column of tableHeaders">
                    <th mat-header *matHeaderCellDef> {{column}} </th>
                    <td mat-cell *matCellDef="let item of gridData"> {{item[column]}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="tableHeaders"></tr>
                <tr mat-row *matRowDef="let row; columns: tableHeaders;"></tr>
            </table>
        </td>

    </tbody>
    <tfoot>
        <td>
            <div class="footer-space">&nbsp;</div>
        </td>
    </tfoot>
</table>
<div class=" printonly">

</div>
<div class="header printonly"><img width="100%" style="float: top" src="../../assets/Header.png" /></div>
<div class="footer printonly">*Quantity unit is (KG) <br><img width="100%" style="float: center"
        src="../../assets/Footer.png" /></div>