<!-- nav -->
<nav class="navbar navbar-expand-md navbar-dark bg-dark" *ngIf="currentUser">
    <a class="navbar-brand">
      <img width="60" style="float: left" alt="United Logo" src="../assets/Logo3s.png"/></a>
      <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/" routerLinkActive="active">Home</a>
        </li>
             <li class="nav-item">
            <a class="nav-item nav-link" (click)="logout()">Logout</a>
        </li>      
        <li class="nav-item">
        </li>         
        </ul>
        <P class="text" style="color: #cbcbcb; margin-top:8px; float: right">    | You are logged in as {{currentUser.firstName}} {{currentUser.lastName}}</P>

      </div>
    </nav>

<!-- main app container -->
<div class="container">
    <router-outlet></router-outlet>
</div>

<!-- credits -->
<div class="text-center mt-4 noprint">
    <p>
       United Inventory Software
    </p>

</div>
