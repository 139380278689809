import { ViewChild, NgModule, Component, enableProdMode, HostListener, OnInit, Inject } from '@angular/core';
import { first } from 'rxjs/operators';
import { User } from '../_models';
import { UserService } from '../_services';
import { AgGridAngular } from 'ag-grid-angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RowNode } from 'ag-grid-community';
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.less']
})

export class HomeComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  loading = false;
  users: User[];

  addproduct: FormGroup;
  printselection: FormGroup;
  submitted = false;
  public show = false;
  public mainShow = true;
  public printShow = false;
  public selectedNodes;
  public selectedData;
  displayedColumns: string[] = ['Code', 'Product', 'LotNo', 'Quantity', 'Price', 'Reserved', 'Availiable', 'Damage', 'Inventory', 'Quality', 'CreatedDate', 'Notes'];
  tableHeaders = [];


  gridApi;
  gridColumnApi;
  gridData = [];
  itemValue = '';
  items: Observable<any[]>
  addstatus = '';
  afterPrint = false;
  public totalq: number = 0;
  public totalp: number = 0;
  private value;

  defaultColDef = {
    editable: false,
    sortable: true,
    resizable: true,
    filter: true,
  };
  columnDefsorder = [

    { field: 'id', hide: true, },
    { headerName: "Code", field: 'Code', checkboxSelection: true, width: 95 },
    {
      field: 'Product', width: 190,
    },
    { field: 'LotNo', width: 120 },
    { headerName: "Quantity/KG", field: 'Quantity', filter: 'agNumberColumnFilter', width: 130 },
    { headerName: 'Cost price(C$/KG)', field: 'Price', filter: 'agNumberColumnFilter', width: 110 },
    {
      headerName: "Reserved/KG", field: 'Reserved', filter: 'agNumberColumnFilter', width: 100, cellClassRules: {
        'rag-yellow': 'x > 0',
      },
    },
    {
      headerName: "Availiable/KG", field: 'Availiable', valueGetter: 'data.Quantity - data.Reserved - data.Damage', filter: 'agNumberColumnFilter', width: 100, cellClassRules: {
        'rag-green': 'x > 500', 'rag-red': 'x <= 500'
      },
    },
    { headerName: "Damaged", field: 'Damage', filter: 'agNumberColumnFilter', width: 100 },
    { headerName: 'Location', field: 'Inventory', width: 120 },
    { field: 'Quality', width: 110 },
    {
      field: 'CreatedDate', filter: 'agDateColumnFilter', filterParams: {
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          var dateParts = cellValue.split('-');
          var day = Number(dateParts[2]);
          var month = Number(dateParts[1]) - 1;
          var year = Number(dateParts[0]);
          var cellDate = new Date(year, month, day);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    },
    { field: 'Notes', width: 250 }
  ];
  columnDefsprint = [
    { field: 'Product', maxWidth: 120 },
    { field: 'LotNo', width: 95 },
    { headerName: 'Quantity/KG', field: 'Quantity', width: 120 },
    { headerName: 'Availiable/KG', field: 'Availiable', valueGetter: 'data.Quantity - data.Reserved - data.Damage', width: 120 },
    { headerName: 'Cost price(C$/KG)', field: 'Price', width: 95 },
    { headerName: 'Loaction', field: 'Inventory', width: 100 },
    { field: 'Quality', width: 100 },
    { field: 'Notes', width: 125 },
  ];

  rowDatasource: Observable<any[]>;
  name = '';
  pname = '';

  fdata: any[] = [];
  batchproducts: any;


  inventory = [
    {
      id: 1,
      name: 'Internal'
    },
    {
      id: 2,
      name: 'External'
    },
    {
      id: 3,
      name: 'Pending'
    }
  ];

  products: any;
  quality = [
    {
      id: 1,
      name: 'Organic'
    },
    {
      id: 2,
      name: 'Conventional'
    }
  ];



  // tslint:disable-next-line:typedef max-line-length
  firestore: any;
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private db: AngularFirestore,



  ) {

    this.rowDatasource = db.collection('products').valueChanges({ idField: 'id' });

  }
  generatePinnedBottomData() {
    // generate a row-data with null values
    let result = {};

    this.gridColumnApi.getAllGridColumns().forEach(item => {
      // console.log(item)
      result[item.colId] = null;
    });
    //console.log(result)
    return this.calculatePinnedBottomData(result);
  }

  calculatePinnedBottomData(target: any) {

    //console.log(target);
    //**list of columns fo aggregation**
    let columnsWithAggregation = ['Reserved', 'Quantity', 'Price', 'Availiable', 'Damage']
    columnsWithAggregation.forEach(element => {
      //console.log('element', element);
      this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        //console.log(rowNode.data);
        //}
        if (rowNode.data[element])
          target[element] += Number(rowNode.data[element]);
      });
      if (target[element])
        target[element] = `${target[element]}`;
    })
    //console.log(target);
    let x = 'Product'
    Object.keys(target).forEach(key => {
      if (key == x) {
        console.log(target[key])
        target[key] = "Total"
      }
    });
    return target;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //console.log(this.gridColumnApi.getAllGridColumns())
    setTimeout(() => {
      let pinnedBottomData = this.generatePinnedBottomData();
      //console.log(pinnedBottomData)
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);

    }, 900)
  };
  getData() {
    const gridData = [];
    this.gridApi.forEachNodeAfterFilterAndSort(function (node) {
      gridData.push(node.data);
    })
    this.gridData = gridData
    this.findsum(this.gridData);
  }
  findsum(data) {
    // debugger  
    this.value = data
    // console.log(this.value);
    this.totalp = 0
    this.totalq = 0
    for (let j = 0; j < data.length; j++) {
      (this.totalq += Number(this.value[j].Quantity));
      (this.totalp += Number(this.value[j].Price))
      //console.log(this.totalq)  
    }
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    // tslint:disable-next-line:max-line-length
    this.loading = true;
    this.userService.getAll().pipe(first()).subscribe(users => {
      this.loading = false;
      this.users = users;

    });

    this.addproduct = this.formBuilder.group({
      Code: [{ value: '', disabled: true }, Validators.required],
      Product: ['', Validators.required],
      LotNo: ['', Validators.required],
      Quantity: [0, Validators.required],
      Price: [0, Validators.required],
      Reserved: [0],
      Damage: [0],
      Availiable: [0],
      Inventory: ['', Validators.required],
      Quality: ['', Validators.required],
      CreatedDate: ['', Validators.required],
      Notes: [''],
      acceptTerms: [false, Validators.requiredTrue]
    });

    this.printselection = this.formBuilder.group({
      Code: [false],
      Product: [true],
      LotNo: [false],
      Quantity: [true],
      Price: [false],
      Reserved: [false],
      Availiable: [true],
      Damage: [false],
      Inventory: [false],
      Quality: [true],
      CreatedDate: [false],
      Notes: [false],
    });
    this.db.collection('productnames').valueChanges({ idField: 'id' }).subscribe(data => { this.products = data });
  }
  print() {
    this.printShow = true

  }

  export() {
    this.tableHeaders = []
    this.displayedColumns.forEach(item => {

      if (this.printselection.get(item).value == true) {
        this.tableHeaders.push(item)

      }
    },
    )
    this.getData();
    //this.generatePinnedBottomData()
    // this.setTotal()
    // Tranfer the present state of the grid (Sort, Order etc to the export component)
    //document.getElementById('myGrid').style.width = "100%";
    //document.getElementById('myGrid').style.fontSize = "8";
    //this.agGrid.api.setDomLayout('autoHeight');
    //this.agGrid.api.setColumnDefs(this.columnDefsprint);
    // this.agGrid.api.sizeColumnsToFit();
    //this.gridApi.setDomLayout('print');

    setTimeout(() => {
      // call print after a time out so that print layout loads properly
      window.print();
      //this.afterPrint = true;
    }, 400);
  }
  closeprint() {
    this.printShow = false
  }
  // tslint:disable-next-line:typedef
  getSelectedRows() {
    this.selectedNodes = this.agGrid.api.getSelectedNodes();
    this.selectedData = this.selectedNodes.map(node => node.data);
    if (this.selectedData.length === 0) {
      alert('Please select item first');
    } else {
      this.mainShow = false
      // tslint:disable-next-line:max-line-length
      //const selectedDataStringPresentation = selectedData.map(node => '{ ' + '"Code:" ' + node.Code + ' ' + node.Product + ' ' + node.LotNo + ' ' + node.Damage + ' ' + node.Inventory + ' ' + node.Quantity + ' ' + node.Quality + ' ' + node.CreatedDate + ' ' + node.Notes + '}').join(', ');
      // tslint:disable-next-line:one-variable-per-declaration
      const pname = this.selectedData.map(node => '' + node.Product + '').join(', ');
      const lotnum = this.selectedData.map(node => '' + node.LotNo + '').join(', ');
      const qntty = this.selectedData.map(node => '' + node.Quantity + '').join(', ');
      const prce = this.selectedData.map(node => '' + node.Price + '').join(', ');
      const rsrv = this.selectedData.map(node => '' + node.Reserved + '').join(', ');
      const damge = this.selectedData.map(node => '' + node.Damage + '').join(', ');
      const inv = this.selectedData.map(node => '' + node.Inventory + '').join(', ');
      const qlty = this.selectedData.map(node => '' + node.Quality + '').join(', ');
      const cdate = this.selectedData.map(node => '' + node.CreatedDate + '').join(', ');
      const nots = this.selectedData.map(node => '' + node.Notes + '').join(', ');


      this.toggle(true, 'edit');
      //this.setProduct(pname, lotnum, qntty,damge, inv, qlty, cdate, nots);
      const prod = this.products.find(el => el.name === pname);
      if (prod) {
        this.addproduct.get('Product').patchValue(prod);
        this.addproduct.controls.Code.setValue(prod.id);
        this.addproduct.controls.Code.disable();
        this.addproduct.controls.Product.setValue(prod.name);
        this.addproduct.controls.Product.disable();
        this.addproduct.controls.LotNo.setValue(lotnum);
        this.addproduct.controls.LotNo.disable();
        // tslint:disable-next-line:no-unused-expression
        this.addproduct.controls.Quantity.setValue(+qntty);
        this.addproduct.controls.Price.setValue(+prce);
        this.addproduct.controls.Reserved.setValue(+rsrv);
        this.addproduct.controls.Damage.setValue(+damge);
        this.addproduct.controls.Notes.setValue(nots);
        const invt = this.inventory.find(el => el.name === inv);
        if (invt) {
          this.addproduct.controls.Inventory.setValue(invt.name);
        }
        this.addproduct.controls.CreatedDate.setValue(cdate);
        this.addproduct.controls.CreatedDate.disable();
        const qltty = this.quality.find(el => el.name === qlty);
        if (qltty) {
          this.addproduct.controls.Quality.setValue(qltty.name);
        }
      }
    }
  }
  // tslint:disable-next-line:typedef
  //  setProduct(pname, lotnum, qntty, damge, inv, qlty, cdate, nots) {        }
  // tslint:disable-next-line:typedef
  toggle(status, addstatus) {
    this.addproduct.controls.Product.enable();
    this.addproduct.controls.CreatedDate.enable();
    this.addproduct.controls.LotNo.enable();
    this.addproduct.controls.Availiable.disable();
    this.show = status;
    this.addstatus = addstatus;
    if (status === false) { this.mainShow = true; } else {
      this.mainShow = false;
    }
  }
  // tslint:disable-next-line:typedef
  onOptionsSelected(value: string) {
    const itemf = this.addproduct.controls.Product.value;
    const item = this.products.find(el => el.name === itemf);
    if (item) {
      this.addproduct.controls.Code.setValue(item.id);
    }
  }
  // tslint:disable-next-line:typedef
  get f() { return this.addproduct.controls; }

  // tslint:disable-next-line:typedef
  onSubmit() {
    let qnty = +this.addproduct.controls.Quantity.value
    let rsrvd = +this.addproduct.controls.Reserved.value
    let dmgd = +this.addproduct.controls.Damage.value
    const avlbl = qnty - rsrvd - dmgd
    this.addproduct.controls.Availiable.setValue(+avlbl)
    if (avlbl < 0) {

      alert("Reserved and Damaged value can't be greater than total quantity")
      return;

    }
    this.submitted = true;
    // stop here if form is invalid
    if (this.addproduct.invalid) {
      return;
    }
    // display form values on success
    const fdata: any[] = this.addproduct.getRawValue();
    alert(JSON.stringify(fdata))
    if (this.addstatus === 'add') {
      this.db.collection('products').add(fdata)
        .then(result => {
          //alert(result .collection.name)
          this.show = false
          this.addproduct.reset
          this.mainShow = true
        }, err => (err))
        .catch(e => {
          alert("Error" + e.data);
        })
    } else if (this.addstatus === 'edit') {
      const itemID = this.selectedData.map(node => node.id).join(',')
      this.db
        .collection('products')
        .doc(itemID)
        .set(fdata).then(result => {
          this.show = false
          this.addproduct.reset()
          this.mainShow = true
          this.submitted = false;
        }, err => (err))
        .catch(e => {
          alert("Error" + e.data);
        })
    }
  }

  // tslint:disable-next-line:typedef
  onReset() {
    this.submitted = false;
    this.addproduct.reset();
  }
  // tslint:disable-next-line:typedef
  delete(whatever: any) {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    // tslint:disable-next-line:no-non-null-assertion
    if (selectedNodes.length === 0) {
      window.alert('Please select item first')
    } else {
      if (window.confirm('Are sure you want to delete this item ?')) {
        if (window.confirm('This item will be Deleted ' + '"' + selectedData.map(node => '' + node.Product + '').join(', ') + '"')) {
          this.db
            .collection('products')
            .doc(selectedData.map(node => '' + node.id + '').join(', '))
            .delete();
        }
      }
    }
  }

}
